.site-title {
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;

  a {
    color: $greyc;
    text-decoration: none;

    &:hover,
    &:focus,
    &:visited {
      color: $grey3;
      text-decoration: none;
    }
  }
}
