#backToTop {
  background: $black;
  bottom: 50px;
  color: $white;
  display: none;
  float: right;
  font-size: 13px;
  height: auto;
  line-height: 1.8;
  padding: 5px 10px;
  position: fixed;
  right: 10px;
  width: auto;
  z-index: 100;
}

@include media-breakpoint-up(xxs) {
  #backToTop {
    bottom: 30px;
    right: 30px;
  }
}
