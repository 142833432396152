h1 {
  font-weight: 600;
}

h1,
h2 {
  font-family: 'Open Sans', sans-serif;
}

p {
  word-wrap: break-word;
}

a {
  word-break: break-word;
  white-space: normal;
}
