@import 'bootstrap/bootstrap';

@import 'partials/variables';
// @import 'partials/mixins';
@import 'partials/structure';
@import 'partials/type';
@import 'partials/forms';
@import 'partials/header';
@import 'partials/nav';
@import 'partials/footer';
@import 'partials/portfolio';
@import 'partials/project-single';
@import 'partials/helpers';
@import 'partials/back-to-top';
