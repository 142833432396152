.portfolio-item-container {
  margin: 20px;
  width: 100%;
}

.portfolio-item {
  box-shadow: 0 1px 3px $box-rgba-3;
  display: flex;
  padding: 0;

  &:hover {
    box-shadow: 0 1px 3px $box-rgba-3;
  }
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  position: static;
}

.portfolio-item--meta {
  font-size: 1.5rem;
}

.portfolio-item--image {
  height: auto;
  object-fit: cover;
  object-position: top left;
  padding: 20px;
  width: 400px;
}

.portfolio-item--thumbnail {
  min-width: 400px;
}

.portfolio-item--title {
  a {
    color: #000;
  }
}

.portfolio-item--description {
  padding: 20px 20px 20px 40px;
}
