.project-single-content {
  .project-title--h1 {
    margin: 0 0 1.5rem;
  }

  .project-tagline {
    color: $greyc;
  }

  .project-container {
    font-family: 'Open Sans', sans-serif;
    margin: 0 0 1.5rem;
  }

  .project-nav {
    background: $greyfa none repeat scroll 0% 0%;
    display: none;
    padding: 1rem;
  }

  .project-img-gallery {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-flow: row wrap;
      grid-gap: unset;
      justify-content: space-between;
      overflow: hidden;
    }
  }

  .column-gallery {
    .project-img {
      flex-flow: column-reverse wrap;

      h3 {
        text-align: center;
      }

      img {
        max-width: initial;
        width: 100%;
      }
    }
  }

  .project-img {
    border: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    margin: 22px auto;
    padding: 22px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      display: flex;
      height: auto;
      width: 98%;
    }

    img {
      margin: 22px auto;
      max-height: 400px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        height: 100%;
        margin: 0 auto;
        max-height: 400px;
        max-width: 33vw;
        object-position: top center;
        width: 33vw;
      }
    }
  }

  .project-img-desc {
    font-size: 1.25rem;
    margin: 0 0 1rem 1rem;
    padding: 0 2rem 0 0;
    text-align: left;
  }
}
