.site-footer {
  background-color: $white;
  color: $grey5;
  font-size: 14px;
  text-align: center;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0;
  }
}
