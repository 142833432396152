.top-nav {
  background: $grey3;

  a {
    color: $greyc;
    text-transform: uppercase;
  }

  .nav-link {
    color: $greyc;
    font-family: Lato, sans-serif;
    font-size: 16px;
    padding: 12px 36px 12px 0;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .top-nav {
    .navbar-nav {
      .nav-link {
        padding: 12px 24px 12px 0;
      }
    }
  }
}

.nav-social-icons {
  margin: 0;
  padding: 0 24px 0 0;

  .fab {
    font-size: 2em;
    margin: $nav--item-margin;
  }

  .first-icon {
    margin-left: 0;
  }
}

@include media-breakpoint-up(lg) {
  .nav-social-icons {
    margin: auto;
    padding: 0 24px 0 0;

    .fab {
      font-size: 1.75rem;
      margin: 0;
      padding: 0 12px;
    }
  }
}

@include media-breakpoint-up(lg-2) {
  .nav-social-icons {
    padding: 0;
  }
}

.fa-github {
  color: $greyd;

  &:hover {
    color: $white;
  }
}

.fa-twitter {
  color: $twitter-blue;

  &:hover {
    color: $white;
  }
}

#topNav {
  justify-content: flex-end;
}

.navbar-brand {
  white-space: normal;
  word-break: break-word;
}

.navbar-toggler {
  margin: 0 10px 0 auto;
  padding: 0.25rem 0.75rem 0.25rem 0px;

  @include media-breakpoint-up(sm) {
    padding: 0.25rem 0.75rem;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  &:focus {
    color: $greyc;
  }
}

.navbar-toggler-icon {
  background-image: escape-svg($navbar-dark-toggler-icon-bg);
}

.navbar-toggler-text {
  color: $greyc;
  margin: 0 0 0 4px;
  position: relative;
  top: 2px;
}
