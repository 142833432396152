input,
textarea {
  display: block;
  margin: 0 0 1rem;
  min-height: 40px;
  padding: 10px;
  width: 100%;
}

label {
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
}

textarea {
  display: block;
}

button {
  font-size: 1.25rem;
}
