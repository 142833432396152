html,
body {
  height: 100%;
}

body {
  background-color: $greyf5;
  display: flex;
  flex-direction: column;
}

img {
  align-self: center;
  max-width: 100%;
  object-fit: contain;
}

.site-header,
.page-content {
  background: $white;
}

.title-area,
.nav-wrap,
.page-content {
  margin: 0 auto;
  max-width: 1140px;
}

.title-area {
  padding: 28px 12px 12px;
}

.page-content {
  margin: 0 auto;
  padding: 40px 40px;
}

.site-footer {
  margin: auto auto 0;
  padding: 20px;
  width: 100%;
}
